<template>
  <div class="container">
    <h1>MAHONEKO</h1>
    <h2>魔法喵</h2>
    <p>www.mahoneko.com</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.container {
  height: 100%;
  background-color: #000000;
  color: #f5f5f5;
}

h1 {
  font-size: 12rem;
  text-align: center;
  position: relative;
  top: 30%;
  font-family: "Lobster", cursive;
}

h2 {
  font-size: 6rem;
  text-align: center;
  position: relative;
  top: 32%;
  font-family: "ZCOOL KuaiLe", cursive;
}

p {
  font-size: 2rem;
  text-align: center;
  position: relative;
  top: 34%;
  font-family: "Montserrat", sans-serif;
}
</style>